function beginWaitTimeQuerying(t, e) { var n = e || 6e4; function u(t, e) { var n = t.formatFunction; var i = r(t.timeType, t.queueId, e); if (typeof n !== "function") { return i } return n(i) } function r(t, e, n) { if (t === "hospitalWait") return n.hospital_waits.current_wait; if (t === "hospitalPatientsInLine") return n.hospital_waits.queue_length; if (t === "hospitalTotalPatients") return n.hospital_waits.queue_total; if (t === "queueWait") return i(e, n).current_wait; if (t === "queuePatientsInLine") return i(e, n).current_patients_in_line; if (t === "queueWaitRange") return i(e, n).current_wait_range; return "Invalid timeType" } function i(t, e) { var n = e.appointment_queues.length; for (var i = 0; i < n; i++) { if (e.appointment_queues[i].queue_id === t) { return e.appointment_queues[i].queue_waits } } return { current_wait: "Queue Not Found", current_patients_in_line: "Queue Not Found", current_wait_range: "Queue Not Found" } } function a(i) { return function (t) { var e; for (var n = 0; n < i.length; n++) { e = u(i[n], t); jQuery(i[n].selector).html(e) } } } function o(t) { var e = {}, n = t.length, i, u; for (var r = 0; r < n; r++) { u = t[r]; i = u.hospitalId; if (e[i] === undefined) { e[i] = [u] } else { e[i].push(u) } } return e } function s(t) { var e = Object.keys(t), n, i; for (var u = 0; u < e.length; u++) { var r = e[u]; if (!r) continue; n = t[r]; i = "https://api.clockwisemd.com/v1/hospitals/" + r + "/waits"; jQuery.ajax({ url: i, method: "GET", success: a(n) }) } } var f = o(t); s(f); setInterval(function () { s(f) }, n) }

jQuery(document).ready(function ($) {
  var WAIT_FETCH_OBJECTS = [
    {
      hospitalId: 4352,
      timeType: 'hospitalWait',
      selector: '#current-wait'
    }
  ];
  beginWaitTimeQuerying(WAIT_FETCH_OBJECTS);
});